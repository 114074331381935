/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "grommet"
import React from "react"
import { useTrail, animated } from "react-spring"

interface TrailProps {
  text: string
}

const Trail: React.FC<TrailProps> = ({ text }) => {
  const items = text.split(``)
  const trail = useTrail(items.length, {
    config: { tension: 900, friction: 50 },
    opacity: 1,
    delay: 500,
    animationTimingFunction: `cubic-bezier(0, 1.15, 0.99, 1)`,
    x: 0,
    y: 0,
    from: { opacity: 0, x: 20, y: 20 },
  })
  return (
    <Box direction="row">
      {trail.map(({ x, y, ...rest }: any, index: number) => (
        <animated.div
          key={index}
          style={{ ...rest, transform: x.interpolate((x: number) => `translate3d(${x}px,${y}px,0)`) }}
        >
          <animated.div>{items[index] === ` ` ? <span>&nbsp;</span> : items[index]}</animated.div>
        </animated.div>
      ))}
    </Box>
  )
}

export default Trail
